import { BsCircleFill } from "react-icons/bs";
import React from "react";

export const Pill = ({ children, color }) => {
    return (
        <div className="flex">
            <div className={`${color} rounded-full text-white`}>
                <div className={"flex gap-2 justify-center items-center py-2 px-4"}>
                    <BsCircleFill className="text-xxs" />
                    {children}
                </div>
            </div>
        </div>
    );
};
