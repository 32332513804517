import React from "react";
import { Pill } from "../../../components/Pill/Pill";

const pillConfig = status => {
    switch (status) {
        case "RASCUNHO":
            return <Pill color="bg-C8">{status}</Pill>;
        case "CADASTRADO":
            return <Pill color="bg-azul">{status}</Pill>;
        case "ASSINADO":
            return <Pill color="bg-verde_3">{status}</Pill>;
    }
};

export default pillConfig;
